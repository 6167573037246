import { HoverMesh } from "../c/hover-mesh";
import { HoverGroup } from "../c/hover-group";/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.0 --types --keepnames --root ./meshes -o roof121.tsx крыша121.glb 
*/

import * as THREE from 'three'
import React from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'

type GLTFResult = GLTF & {
  nodes: {
    ['8']: THREE.Mesh
    ['7']: THREE.Mesh
    ['4']: THREE.Mesh
    ['6']: THREE.Mesh
    ['3']: THREE.Mesh
    ['2']: THREE.Mesh
    ['1']: THREE.Mesh
    ['5']: THREE.Mesh
  }
  materials: {
    ['scene2 - var1.001']: THREE.MeshStandardMaterial
  }
  
}

export function Model(props: JSX.IntrinsicElements['group']) {
  const { nodes, materials } = useGLTF('./meshes/крыша121.glb') as GLTFResult
  return (
    <HoverGroup {...props} dispose={null}>
      <HoverMesh name="8" geometry={nodes['8'].geometry} material={materials['scene2 - var1.001']} position={[0, 0, 0.067]} rotation={[0, 0, 0.873]} />
      <HoverMesh name="7" geometry={nodes['7'].geometry} material={materials['scene2 - var1.001']} position={[-0.034, 0.029, 0.067]} rotation={[0, 0, 0.873]} />
      <HoverMesh name="4" geometry={nodes['4'].geometry} material={materials['scene2 - var1.001']} position={[0.288, 1.063, 0.162]} rotation={[0, 0, 0.873]} />
      <HoverMesh name="6" geometry={nodes['6'].geometry} material={materials['scene2 - var1.001']} position={[1.179, -0.989, 1.318]} rotation={[Math.PI / 2, 0.873, 0]} />
      <HoverMesh name="3" geometry={nodes['3'].geometry} material={materials['scene2 - var1.001']} position={[1.696, -1.423, -0.223]} rotation={[Math.PI / 2, 0.873, 0]} />
      <HoverMesh name="2" geometry={nodes['2'].geometry} material={materials['scene2 - var1.001']} position={[1.683, -1.412, 0.547]} rotation={[Math.PI / 2, 0.873, 0]} />
      <HoverMesh name="1" geometry={nodes['1'].geometry} material={materials['scene2 - var1.001']} position={[0.776, 2.679, 0]} rotation={[0, 0, 0.873]} />
      <HoverMesh name="5" geometry={nodes['5'].geometry} material={materials['scene2 - var1.001']} position={[-0.25, 0.21, 0.665]} rotation={[0, 0, 0.873]} />
    </HoverGroup>
  )
}

// useGLTF.preload('./meshes/крыша121.glb')
