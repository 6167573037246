import { HoverMesh } from "../c/hover-mesh";
import { HoverGroup } from "../c/hover-group";/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.0 --types --keepnames --root ./meshes -o floors31.tsx полы31.glb 
*/

import * as THREE from 'three'
import React from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'

type GLTFResult = GLTF & {
  nodes: {
    ['2']: THREE.Mesh
    ['4']: THREE.Mesh
    ['9']: THREE.Mesh
    ['10']: THREE.Mesh
    ['8']: THREE.Mesh
    ['5']: THREE.Mesh
    ['1']: THREE.Mesh
    ['3']: THREE.Mesh
    ['6']: THREE.Mesh
    ['7']: THREE.Mesh
  }
  materials: {
    ['scene2 - var1.005']: THREE.MeshStandardMaterial
  }
  
}

export function Model(props: JSX.IntrinsicElements['group']) {
  const { nodes, materials } = useGLTF('./meshes/полы31.glb') as GLTFResult
  return (
    <HoverGroup {...props} dispose={null}>
      <HoverMesh name="2" geometry={nodes['2'].geometry} material={materials['scene2 - var1.005']} position={[-0.363, 0.602, -0.488]} rotation={[-Math.PI / 2, 0, 0]} />
      <HoverMesh name="4" geometry={nodes['4'].geometry} material={materials['scene2 - var1.005']} position={[-0.469, 0.545, -0.494]} rotation={[-Math.PI / 2, 0, 0]} />
      <HoverMesh name="9" geometry={nodes['9'].geometry} material={materials['scene2 - var1.005']} position={[-0.206, 0.133, -0.207]} rotation={[-Math.PI / 2, 0, 0]} />
      <HoverMesh name="10" geometry={nodes['10'].geometry} material={materials['scene2 - var1.005']} position={[-0.152, 0, -0.153]} rotation={[-Math.PI / 2, 0, 0]} />
      <HoverMesh name="8" geometry={nodes['8'].geometry} material={materials['scene2 - var1.005']} position={[-0.248, 0.21, -0.239]} rotation={[-Math.PI / 2, 0, 0]} />
      <HoverMesh name="5" geometry={nodes['5'].geometry} material={materials['scene2 - var1.005']} position={[-0.525, 0.507, -0.388]} rotation={[-Math.PI / 2, 0, 0]} />
      <HoverMesh name="1" geometry={nodes['1'].geometry} material={materials['scene2 - var1.005']} position={[-0.613, 0.667, -0.838]} rotation={[-Math.PI / 2, 0, 0]} />
      <HoverMesh name="3" geometry={nodes['3'].geometry} material={materials['scene2 - var1.005']} position={[-0.569, 0.647, -0.343]} rotation={[-Math.PI / 2, 0, 0]} />
      <HoverMesh name="6" geometry={nodes['6'].geometry} material={materials['scene2 - var1.005']} position={[-0.404, 0.474, -0.391]} rotation={[-Math.PI / 2, 0, 0]} />
      <HoverMesh name="7" geometry={nodes['7'].geometry} material={materials['scene2 - var1.005']} position={[0.085, 0.372, -0.047]} rotation={[-Math.PI / 2, 0, 0]} />
    </HoverGroup>
  )
}

// useGLTF.preload('./meshes/полы31.glb')
