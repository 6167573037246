import { HoverMesh } from "../c/hover-mesh";
import { HoverGroup } from "../c/hover-group";/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.0 --types --keepnames --root ./meshes -o f02.tsx фундамент02.glb 
*/

import * as THREE from 'three'
import React from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'

type GLTFResult = GLTF & {
  nodes: {
    ['10']: THREE.Mesh
    ['3']: THREE.Mesh
    ['13']: THREE.Mesh
    ['6']: THREE.Mesh
    ['12']: THREE.Mesh
    ['1']: THREE.Mesh
    ['9']: THREE.Mesh
    ['5']: THREE.Mesh
    ['4']: THREE.Mesh
    ['7']: THREE.Mesh
    ['8']: THREE.Mesh
    ['2']: THREE.Mesh
    ['11']: THREE.Mesh
  }
  materials: {
    ['scene2 - var1']: THREE.MeshStandardMaterial
  }
  
}

export function Model(props: JSX.IntrinsicElements['group']) {
  const { nodes, materials } = useGLTF('./meshes/фундамент02.glb') as GLTFResult
  return (
    <HoverGroup {...props} dispose={null}>
      <HoverMesh name="10" geometry={nodes['10'].geometry} material={materials['scene2 - var1']} position={[0.541, 0.19, -0.153]} />
      <HoverMesh name="3" geometry={nodes['3'].geometry} material={materials['scene2 - var1']} position={[0.613, 0.526, -0.157]} />
      <HoverMesh name="13" geometry={nodes['13'].geometry} material={materials['scene2 - var1']} position={[0.031, 0, -0.153]} />
      <HoverMesh name="6" geometry={nodes['6'].geometry} material={materials['scene2 - var1']} position={[-0.754, 0.514, -0.97]} />
      <HoverMesh name="12" geometry={nodes['12'].geometry} material={materials['scene2 - var1']} position={[0.532, 0.031, 0.655]} />
      <HoverMesh name="1" geometry={nodes['1'].geometry} material={materials['scene2 - var1']} position={[0.383, 0.091, -1.416]} />
      <HoverMesh name="9" geometry={nodes['9'].geometry} material={materials['scene2 - var1']} position={[-0.759, 0.513, 0.071]} />
      <HoverMesh name="5" geometry={nodes['5'].geometry} material={materials['scene2 - var1']} position={[0.541, 0.548, -0.182]} />
      <HoverMesh name="4" geometry={nodes['4'].geometry} material={materials['scene2 - var1']} position={[-0.754, 0.514, -0.97]} />
      <HoverMesh name="7" geometry={nodes['7'].geometry} material={materials['scene2 - var1']} position={[0.541, 0.548, -0.182]} />
      <HoverMesh name="8" geometry={nodes['8'].geometry} material={materials['scene2 - var1']} position={[0.541, 0.548, -0.153]} />
      <HoverMesh name="2" geometry={nodes['2'].geometry} material={materials['scene2 - var1']} position={[0.541, 0.548, -0.153]} />
      <HoverMesh name="11" geometry={nodes['11'].geometry} material={materials['scene2 - var1']} position={[0.031, 0, -0.153]} />
    </HoverGroup>
  )
}

// useGLTF.preload('./meshes/фундамент02.glb')
