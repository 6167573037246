import { FC, MouseEventHandler } from "react";
import { IconArrowLeft } from "./icons/arrow-left";

interface Props {
  onBackClick?: MouseEventHandler<HTMLDivElement>;
  onActionClick?: MouseEventHandler<HTMLDivElement>;
  actionHref?: string;
  actionText: string;
}

export const PanelFooter: FC<Props> = ({
  onBackClick,
  actionText,
  onActionClick,
  actionHref,
}) => (
  <div className="footer px-6 py-4 bg-dark justify-start items-center gap-4 w-full flex">
    <div
      onClick={onBackClick}
      className="cursor-pointer hover:opacity-75 group grow shrink basis-0 h-10 px-4 border border-white justify-center items-center gap-1 flex"
    >
      <div className="pr-4 justify-center items-center gap-2 flex">
        <IconArrowLeft className="w-4 h-4 transform transition-transform group-hover:-translate-x-0.5" />
        <div className=" text-white text-base font-normal uppercase">Назад</div>
      </div>
    </div>
    {actionHref ? (
      <a
        href={actionHref}
        target="_blank"
        className="cursor-pointer hover:opacity-75 grow shrink basis-0 h-10 px-4 bg-gradient justify-center items-center flex"
      >
        <div className=" text-white text-base font-normal uppercase">
          {actionText}
        </div>
      </a>
    ) : (
      <div
        onClick={onActionClick}
        className="cursor-pointer hover:opacity-75 grow shrink basis-0 h-10 px-4 bg-gradient justify-center items-center flex"
      >
        <div className=" text-white text-base font-normal uppercase">
          {actionText}
        </div>
      </div>
    )}
  </div>
);
