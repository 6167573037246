import { useDispatch } from "app/store";
import { MdOutlineViewInAr } from "react-icons/md";
import { incDefaultView } from "../slice";

export const DefaultViewButton = () => {
  const dispatch = useDispatch();
  return (
    <div
      className="fixed top-2 right-[23.5rem] cursor-pointer w-10 h-10 hover:opacity-75"
      onClick={() => dispatch(incDefaultView())}
    >
      <MdOutlineViewInAr className="text-gray-900 w-10 h-10 drop-shadow-sm" />
    </div>
  );
};
