import { IoSettingsSharp } from "react-icons/io5";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useDispatch, useSelector } from "app/store";
import { FilterItem, setFilterItems, setScreen } from "../slice";
import { useState } from "react";
import { CloseButton } from "./close-button";
import { FilterSelector } from "./filter-selector";
import ReactSlider from "react-slider";
import { FilterRange } from "./filter-range";
import { IconHome } from "./icons/home";
import { IconChevronLeft } from "./icons/chevron-left";
import { IconRoof } from "./icons/roof";
import { IconPrice } from "./icons/price";
import { IconEnergy } from "./icons/energy";
import { IconConstruction } from "./icons/construction";
import { IconLifetime } from "./icons/lifetime";
import c from "classnames";
import { IconFilter } from "./icons/filter";
import { NumberCircle } from "./number-circle";
import { IconBase } from "./icons/base";
import { IconGround } from "./icons/ground";
import { IconFloors } from "./icons/floors";
import { IconWalls } from "./icons/walls";
import { IconInterfloor } from "./icons/interfloor";
import { IconArrowLeft } from "./icons/arrow-left";
import { Collapse } from "./collapse";
import { FancyDropdown } from "./fancy-dropdown";
import { HouseLeftBar } from "./house-left-bar";
import { Panel } from "./panel";
import { TextItem } from "./text-item";
import { NumberItem } from "./number-item";
import { CardSubheader } from "./card-subheader";
import { NumberCircleItem } from "./number-circle-item";
import { InactiveHouseItem } from "./inactive-house-item";
import { FlexDropdown } from "./flex-dropdown";
import { PanelFooter } from "./panel-footer";
import { SmartHouseItem } from "./smart-house-item";

export const House = () => {
  const dispatch = useDispatch();
  const props = useSelector(s => s.ui.houses[s.ui.selection.house]);
  const selection = useSelector(s => s.ui.selection);
  const models = useSelector(s => s.ui.models);
  // console.log("house", props, selection, models);

  const uiVisible = useSelector(s => s.ui.uiVisible);
  if (!uiVisible) return null;
  const selectModel = (model: string) => {
    if (!models[model]) return null;
    const variants = models[model].variants;
    if (!selection.options[model]) return null;
    const variant = variants[selection.options[model][0]];
    const tier = variant.tiers[selection.options[model][1]];
    return { variant, tier };
  };
  const selectedInterfloor = selectModel("межэтажное");
  const selectedWall = selectModel("стены");
  const selectedFloor = selectModel("полы");
  const selectedFoundation = selectModel("фундамент");

  let energy = props.energy;
  let constructionTime = props.constructionTime;
  let lifetime = props.lifetime;
  if (
    selectedInterfloor ||
    selectedWall ||
    selectedFloor ||
    selectedFoundation
  ) {
    let sumEnergy = 0;
    let sumConstructionTime = 0;
    let sumLifetime = 0;
    let countEnergy = 0;
    let countConstructionTime = 0;
    let countLifetime = 0;
    for (let part of [
      selectedInterfloor,
      selectedWall,
      selectedFloor,
      selectedFoundation,
    ]) {
      if (!part) {
        continue;
      }
      let localCountLifetime = 0;
      let localSumLifetime = 0;
      for (let stat of part.tier.stats) {
        if (stat.name.toLowerCase() === "энергоэффективность") {
          countEnergy++;
          sumEnergy += stat.value;
        } else if (stat.name.toLocaleLowerCase() === "скорость монтажа") {
          countConstructionTime++;
          sumConstructionTime += stat.value;
        } else {
          localSumLifetime += stat.value;
          localCountLifetime++;
        }
      }
      if (localCountLifetime) {
        countLifetime++;
        sumLifetime += localSumLifetime / localCountLifetime;
      }
    }
    if (countEnergy) {
      energy = Math.ceil(sumEnergy / countEnergy);
    }
    if (countConstructionTime) {
      constructionTime = Math.ceil(sumConstructionTime / countConstructionTime);
    }
    if (countLifetime) {
      lifetime = Math.ceil(sumLifetime / countLifetime);
    }
  }

  return (
    <>
      <HouseLeftBar />
      <div className="fixed right-0 top-0 h-screen">
        {/* <Button
        className="fixed top-2 right-2 p-4 text-4xl"
        onClick={() => dispatch(setScreen("house"))}
      >
        <IoSettingsSharp />
      </Button> */}
        <Panel title={props.name}>
          <OverlayScrollbarsComponent className="h-full bg-fancy text-dark">
            <div className="filter-bg-wrapper px-6 min-h-full py-4 flex-col flex gap-4">
              <TextItem title="Исполнение" text={props.construction} />
              <TextItem title="Крыша" text={props.roof} />
              <TextItem title="Класс стоимости" text={props.price} />

              <CardSubheader title="Показатели" />

              <NumberItem title="Класс энергоэффективности" number={energy} />
              <NumberItem title="Срок возведения" number={constructionTime} />
              <NumberItem title="Срок эксплуатации" number={lifetime} />

              <div className="indent w-full h-6"></div>
              <SmartHouseItem Icon={IconRoof} title="Крыша" model="крыша" />
              <SmartHouseItem
                Icon={IconInterfloor}
                title="Межэтажное перекрытие"
                model="межэтажное"
              >
                {selectedInterfloor && (
                  <>
                    <TextItem
                      title="Тип перекрытий"
                      text={selectedInterfloor.variant.name}
                    />
                    <TextItem
                      title="Класс стоимости"
                      text={selectedInterfloor.tier.name}
                    />
                    {selectedInterfloor.tier.stats.map((p, i) => (
                      <NumberItem key={i} title={p.name} number={p.value} />
                    ))}
                  </>
                )}
              </SmartHouseItem>
              <SmartHouseItem Icon={IconWalls} title="Стены" model="стены">
                {selectedWall && (
                  <>
                    <TextItem
                      title="Тип стен"
                      text={selectedWall.variant.name}
                    />
                    <TextItem
                      title="Класс стоимости"
                      text={selectedWall.tier.name}
                    />
                    {selectedWall.tier.stats.map((p, i) => (
                      <NumberItem key={i} title={p.name} number={p.value} />
                    ))}
                  </>
                )}
              </SmartHouseItem>
              <SmartHouseItem
                Icon={IconFloors}
                title="Полы по грунту"
                model="полы"
              >
                {selectedFloor && (
                  <>
                    <TextItem
                      title="Тип полов"
                      text={selectedFloor.variant.name}
                    />
                    <TextItem
                      title="Класс стоимости"
                      text={selectedFloor.tier.name}
                    />
                    {selectedFloor.tier.stats.map((p, i) => (
                      <NumberItem key={i} title={p.name} number={p.value} />
                    ))}
                  </>
                )}
              </SmartHouseItem>
              <SmartHouseItem Icon={IconBase} title="Цоколь" model="цоколь" />

              <SmartHouseItem
                Icon={IconGround}
                title="Фундамент"
                model="фундамент"
              >
                {selectedFoundation && (
                  <>
                    <TextItem
                      title="Тип фундамента"
                      text={selectedFoundation.variant.name}
                    />
                    <TextItem
                      title="Класс стоимости"
                      text={selectedFoundation.tier.name}
                    />
                    {selectedFoundation.tier.stats.map((p, i) => (
                      <NumberItem key={i} title={p.name} number={p.value} />
                    ))}
                  </>
                )}
              </SmartHouseItem>
            </div>
          </OverlayScrollbarsComponent>
          <PanelFooter
            onBackClick={() => dispatch(setScreen("filter"))}
            actionHref="https://www.bility.ru"
            actionText="Заказать"
          />
        </Panel>
      </div>
    </>
  );
};
