import { FC } from "react";
import { IconInfo } from "./icons/info";
import { useDispatch, useSelector } from "app/store";
import c from "classnames";
import { setHoverCode } from "../slice";

interface Props {
  title: string;
  caption: string;
  number: number;
  hiddenCode?: number;
  info?: boolean;
}

export const ConfigurationDropdownNumberedItem: FC<Props> = ({
  title,
  caption,
  number,
  hiddenCode,
  info = false,
}) => {
  const dispatch = useDispatch();
  const code = useSelector(s => s.ui.hoverCode);
  const opaque = ![hiddenCode || number, -1].includes(code);
  return (
    <div
      className={c("cursor-pointer item pb-4", {
        "opacity-30": opaque,
      })}
      onMouseEnter={() => dispatch(setHoverCode(hiddenCode || number))}
      onMouseLeave={() => dispatch(setHoverCode(-1))}
    >
      <div className="py-1 flex flex-col justify-start items-start gap-1">
        <div className="flex w-full items-start justify-start gap-4">
          <div className="rounded-full shrink-0 w-6 h-6 bg-dark text-white font-normal flex items-center justify-center">
            {number}
          </div>
          <div className="self-stretch opacity-50 text-dark text-1rem font-normal align-top text-[0.875rem] leading-[140%]">
            {title}
          </div>
        </div>
        <div className="flex w-full items-center justify-start gap-4">
          {info ? (
            <IconInfo className="w-6 h-6 shrink-0" />
          ) : (
            <div className="w-6 h-6 shrink-0"></div>
          )}
          <div className="self-stretch text-dark text-base font-display uppercase tracking-wider">
            {caption}
          </div>
        </div>
      </div>
    </div>
  );
};
